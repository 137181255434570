<template>
    <section>
        <div class="doctor">
            <h2 class="dochead">{{$t("OurDoctormsg")}}</h2>
            <div class="doctordiv">
                <div>
                    <img src="../assets/Icons/DrKarishmaAggarwal.png">
                </div>
                <div class="doctordetail">
                    <h1>Dr. Karishma Aggarwal</h1>
                    <!-- <h2>Founder</h2> -->
                    <hr>
                    <p class="degree">MBBS, M.S. in Ophthalmology

</p>
                    <p>Cornea Specialist, Ocular Surface Surgeon, Refractive Surgeon</p>
                </div>
            </div>
            <div class="biography">
                <p>Dr. Karishma Aggarwal is a skilled ophthalmologist with an MBBS and M.S. in Ophthalmology, specializing in cornea, ocular surface, and refractive surgery. With over four years of experience, she excels in treating a wide range of ocular conditions and is proficient in advanced procedures such as LASIK and keratoplasty. Her strong background in patient care and diagnostic testing allows her to manage hundreds of patients daily, ensuring personalized and effective treatment.<br><br>

                    In addition to her clinical practice, Dr. Aggarwal is actively involved in research and has published papers in esteemed journals, contributing to the advancement of her field. Currently based at Kashi Netralaya in Varanasi, she is dedicated to providing eye care with both compassion and precision, striving to improve the vision and quality of life for her patients. Her commitment to excellence in ophthalmology makes her a respected figure in the medical community.</p>
            </div>
            <div class="minidoctordetail">
                <RouterLink to="/DrDikshaSareen">
                        <div class="minidoctor"> 
                            <img src="../assets/Icons/DrDikshaSareen.png">
                            <div class="minidoctorsec">
                                <h2>Dr. Diksha Sareen </h2>
                                <p class="degrees">M.B.B.S, M.S, D.N.B (Ophth) FLVPEI</p>
                                <p>Cornea Specialist</p>
                                <RouterLink to="/DrDikshaSareen" class="router">
                                    <button> Know More</button>
                                </RouterLink>
                            </div>
                        </div>
                    </RouterLink>
                    <RouterLink to="/DrShantanuGupta">
                <div class="minidoctor"> 
                    <img src="../assets/Icons/DrShantanuGupta.png">
                    <div class="minidoctorsec">
                        <h2>Dr. Shantanu Gupta </h2>
                        <p class="degrees">M.B.B.S, M.S(MAMC,Delhi) D.N.B, F.I.C.O(UK)</p>
                        <p>Medical Retina Surgeon</p>
                        <RouterLink to="/DrShantanuGupta" class="router">
                            <button> Know More</button>
                        </RouterLink>
                    </div>
                </div>
            </RouterLink>
            </div>
        </div>
    </section>
</template>

<script>
import { RouterLink } from 'vue-router';
    export default{
        name:'DoctorKarishmaAggarwalSection',
        components:{ RouterLink}
    }
</script>



<style>

/* 
    .doctor{
        background-image: url('../assets/Bg Images/About Us BG img.png');
        background-size: cover;
        background-position: center;
        width: fit-content;
        height: fit-content;
        text-align: center;
        font-family: sans-serif;
    }

    .doctordiv{
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 40px;
    }

    .doctordetail{
        text-align: start;
        padding: 20px;
    }

    .doctordetail hr{
        width: 520px;
        padding: 2px;
        border: 0;
        border-radius: 10px;
        background-color: var(--bluecolor);
    }

    .doctordetail h1{
        font-size: 3rem;
        font-family: 'Montserrat';
        color: var(--bluecolor);
        padding: 10px;
    }

    .doctordetail h2{
        font-size: 2rem;
        padding: 10px;
    }

    .doctordetail p{
        padding: 10px;
        color: red;
        font-weight: 400;
        font-size: 1.5rem;
    }

    .doctordetail .router{
        padding: 20px 10px 10px 10px;
    }

    .doctordetail button{
        font-size: 1rem;
        padding: 15px;
        background-color: var(--bluecolor);
        color: var(--whitecolor);
        border: 0px solid var(--blackcolor);
        border-radius: 10px;
    }

    .biography{
        background-color: var(--bluecolor);
        text-align: start;
        padding: 40px 100px;
        color: var(--whitecolor);
        margin: 0px 150px 0px 150px;
        border-radius: 20px;
    }    


    .biography h2{
         padding: 10px;
        font-size: 2rem;
    }

    .biography p{
        padding: 40px 10px;
        font-family: 'Nunito';
        font-size: 1.5rem;
    }

    .minidoctordetail{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        padding: 20px   ;
    }
    
    .minidoctor{
        display: flex;
        flex-direction: row;
        border-radius: 20px;
        background-color: var(--whitecolor);
        padding: 10px;
        box-shadow: 20px 20px 30px var(--thirdblack);
    }

    .minidoctorsec{
        display: flex;
        flex-direction: column;
    }

    .minidoctor img{
        width: 30%;
    }

    .minidoctorsec h2{
        color: var(--bluecolor);
        padding: 10px;
    }

    .minidoctorsec p{
        color: var(--blackcolor);
    }

    .minidoctorsec button{
        font-size: 1rem;
        padding: 15px 40px;
        background-color: var(--bluecolor);
        color: var(--whitecolor);
        border: 0px solid var(--blackcolor);
        border-radius: 10px;
    }

    .minidoctorsec .router{
        padding: 10px ;
    } */

</style>