import { createApp } from 'vue';
import App from './App.vue';
import { createI18n } from 'vue-i18n'; 
import Specsremove from './components/specsremove.vue';
import Landing from './components/Landing.vue';
import Cataract from './components/Cataract.vue';
import About from './components/About.vue';
import Speyecare from './components/Speyecare.vue';
import Testimonial from './components/Testimonial.vue';
import CataractSurgery from './components/catsurg.vue';
import Refsolution from './components/Refsolution.vue';
import Retcare from './components/Retcare.vue';
import glaumanage from './components/glaumanage.vue';
import corntransplant from './components/corntransplant.vue';
import PrivacyPolicy from './components/Privacypolicy.vue';
import DrRSingh from './components/DrRSingh.vue';
import DrShashwatSingh from './components/DrShashwatSingh.vue';
import DrShantanuGupta from './components/DrShantanuGupta.vue';
import DrAbhishekDixit from './components/DrAbhishekDixit.vue';
import DrPrakashParimal from './components/DrPrakashParimal.vue';
import DrDikshaSareen from './components/DrDikshaSareen.vue';
import DrSomaiyaSultana from './components/DrSomaiyaSultana.vue';

import './assets/styles/globalColor.css';

import { createRouter, createWebHistory } from 'vue-router';

import en from './localise/en.js';
import hi from './localise/hi.js';
import DrKarishmaAggarwal from './components/DrKarishmaAggarwal.vue';

const routes = [
  {
    path: '/',
    component: Landing,
  },
  {
    path: '/cataract',
    component: Cataract,
  },
  {
    path: '/About',
    component: About,
  },
  {
    path: '/Speyecare',
    component: Speyecare,
  },
  {
    path: '/Testimonial',
    component: Testimonial,
  },
  {
    path: '/ContourVision',
    component: Specsremove,
  },
  {
    path: '/CataractSurgery',
    component: CataractSurgery,
  },
  {
    path: '/ReferactiveSolution',
    component: Refsolution,
  },
  {
    path: '/RetinalCare',
    component: Retcare,
  },
  {
    path: '/GlaucomaManagement',
    component: glaumanage,
  },
  {
    path: '/CornealTransplant',
    component: corntransplant,
  },
  {
    path: '/PrivacyPolicy',
    component: PrivacyPolicy,
  },
  {
    path: '/DrRSingh',
    component: DrRSingh,
  },
  {
    path: '/DrShashwatSingh',
    component: DrShashwatSingh,
  },
  {
    path: '/DrShantanuGupta',
    component: DrShantanuGupta,
  },
  {
    path: '/DrAbhishekDixit',
    component: DrAbhishekDixit,
  },
  {
    path: '/DrPrakashParimal',
    component: DrPrakashParimal,
  },
  {
    path: '/DrDikshaSareen',
    component: DrDikshaSareen,
  },
  {
    path: '/DrSomaiyaSultana',
    component: DrSomaiyaSultana,
  },
  {
    path: '/DrKarishmaAggarwal',
    component: DrKarishmaAggarwal,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
  scrollBehavior: () => ({ top: 0 }),
  global: {
    scrollBehavior: () => ({ top: 0 }),
  },
});

// Create the i18n instance
const i18n = createI18n({
  locale: 'hi', // Default language
  messages: {
    en,
    hi,
  },
});

// Create the Vue app instance
const app = createApp(App);
app.use(i18n);
app.use(router);

// Mount the app to the '#app' element
app.mount('#app');